/**
  process.env.NODE_ENV는 Vue CLI가 자동으로 설정하는 값이기 때문에 변경되지 않는다.
  Vue CLI에서 yarn build를 실행하면 NODE_ENV=production이 기본적으로 설정되며, 
  --mode staging을 사용해도 NODE_ENV 값은 그대로 production으로 유지된다.

  ✅ NODE_ENV 대신 VUE_APP_MODE를 사용하는 이유
    1️⃣ process.env.NODE_ENV는 Vue CLI 내부적으로 사용
      - Vue CLI는 NODE_ENV=production을 자동으로 설정하여 최적화된 빌드를 수행
      - 즉, yarn build --mode staging을 실행해도 process.env.NODE_ENV 값은 변경되지 않는다.
      - 이를 변경하려고 .env.staging에 NODE_ENV=staging을 설정해도 적용되지 않는다.
    2️⃣ process.env.VUE_APP_MODE는 사용자 정의 가능
      - Vue 프로젝트에서 환경을 직접 구분하고 싶다면 VUE_APP_MODE 같은 사용자 정의 변수를 사용하는 것이 좋다.
      - Vue CLI는 VUE_APP_으로 시작하는 변수만 .env.<mode>에서 가져와 사용
      - 따라서, .env.staging에서 VUE_APP_MODE=staging을 설정하면 Vue 코드에서 process.env.VUE_APP_MODE로 접근 가능
 */

console.log(`실행환경 : ${process.env.VUE_APP_MODE}`);      

export default {
  ACT_LOGIN     : "auth/login",
  APP_NAME      : "Pineapple academy",
  APP_USERTYPE  : "EDITOR",
  API_URL       : process.env.VUE_APP_API_URL,
  UPLOAD_URL    : process.env.VUE_APP_UPLOAD_URL,
  NODE_ENV      : process.env.VUE_APP_MODE
};

/**
 * 선언부
 * UPDATE_PHOTO : "/contacts/${no}/photo"
 * 호출부
 * this.$CONF.UPDATE_PHOTO.replace("${no}", 3)
 */
