<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">{{$t('txt.18')}}</h5>
    <p class="mb-0 text-sm">{{$t('txt.19')}}</p>
    <div class="multisteps-form__content">

      <VeeForm
        ref="formRef"
        :validation-schema="schema"
        @submit="onSubmit"
      >
      <div class="row mt-3">
        <!-- 좌측 //-->
        <div class="col-12 col-sm-4">
          <div class="row">
            <div class="col-12">
              <Field v-slot="{ field }" name="chapters">
                <CustomSelect
                  label="Chapters"
                  :options="chapterOptions"
                  :model-value="field.value"
                  @update:modelValue="chapertNumberChange"
                  @blur="field.onBlur"
                />
              </Field>
            </div>
          </div>
          <div class="row" >
            <div class="mt-4 col-12">
              <List
                style="border:1px solid gray;overflow:auto; max-height: 500px;"
                :items-config="itemsConfig"
                :events="sections"
                @update:events="sectionReSorting"
                @view="loadSection"
                @delete="deleteSection"
              />
            </div>
          </div>
          <div class="row" v-if="chapterNumber != null">
            <div class="col-2 col-sm-2 mt-3 mb-0">
              <button class="mt-3 mb-0 btn btn-icon" @click.prevent="onSectionTypeChange('text')">
                <i 
                  class="fa fa-plus" style="font-size:20px;color:red"
                  v-tooltip="$t('txt.19')"
                ></i>
              </button>
            </div> 
          </div>
        </div>

        <!-- 우측 //-->
        <div class="col-12 col-sm-8">
          <div class="row">
            <div class="col-4 col-sm-4">
              <CustomSelect
                label="Section Type"
                :options="sectionTypes"
                v-model="sectionType"
                @update:modelValue="onSectionTypeChange"
              />
            </div>            
          </div>
          <div class="row">
            <div class="col-sm-12">
              <!-- 동적 컴포넌트 로딩 -->
              <component :is="currentComponent" v-model="currentSection" />
            </div>
          </div>
        </div>
      </div>     
      <div class="button-row d-flex mt-4">
        <material-button
          type="submit"
          class="ms-auto mb-0 js-btn-next"
          variant="gradient"
          color="dark"
          >{{$t("btn.04")}}</material-button
        >
      </div>        
      </VeeForm>
    </div>
  </div>
</template>

<script>
import TextSection from "@/components/TextSection.vue";
import QuizSection from "@/components/QuizSection.vue";
import GameSection from "@/components/GameSection.vue";

import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import List from "@/components/List.vue";
//import QuillEditor from "@/components/QuillEditor.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import { Field, Form as VeeForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "UserInfo",
  components: {
    TextSection, QuizSection,GameSection,
    MaterialInput,
    MaterialButton,
    List,
    //QuillEditor,
    CustomSelect,
    Field, VeeForm, ErrorMessage,
  },
  data(){
    return {
      chapterNumber: null,
      sectionNumber: null,
      sectionType: "text",
      chapterOptions: [],
      currentSection: this.getDefaultSection("text"), // 초기값 설정
      sectionTypes: [
        {value:"text", label:"Text"},
        {value:"game", label:"Game"},
        {value:"quiz", label:"Quiz"}
      ],
      sections: [],
      itemsConfig: {
        title: "Sections",
        label: "Section",
      },
      schema: yup.object({}),
    }
  },
  computed: {
    currentComponent() {
      switch (this.sectionType) {
        case "quiz":
          return "QuizSection";
        case "game":
          return "GameSection";
        default:
          return "TextSection";
      }
    },
  },
  methods: {
    async chapertNumberChange(value) {
      //console.log("Selected Value:", value);
      let barcode = this.$route.query.barcode;
      this.chapterNumber = value;
      
      try {
        const res = await this.$axios({
          method: "get",
          url: `/textbook/textbookContents/${barcode}/${this.chapterNumber}`,
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data)) {
          this.sections = res.data.map(item => {
            return {
              idx: item.sectionNumber,
              sort: item.sectionNumber,
              title: item.type
            };
          });
          //console.log(this.sections);
        }
      } catch (error) {
        console.error("Error :", error);
      }

    },
    async getChapters() {

      let barcode = this.$route.query.barcode;
      try {
        const res = await this.$axios({
          method: "get",
          url: `/textbook/textbookContents/${barcode}`,
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data.chapters)) {
          this.chapterOptions = res.data.chapters.map(item => {
            return {
              value: item.chapterNumber,
              label: item.title,
            };
          });
          //console.log(this.chapters);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    getFullImageUrl(imageName, barcode) {
      if (!imageName) return null; // 이미지가 없으면 null 반환
      if (imageName.startsWith("http")) return imageName; // URL이면 그대로 반환
      return `${this.$CONF.API_URL}/textbook/textbookContents/images/${barcode}/${imageName}`;
    },
    async loadSection(idx) {
      let barcode = this.$route.query.barcode;
      this.sectionNumber = idx;

      try {
        // API 호출: 선택한 section 데이터 가져오기
        const res = await this.$axios({
          method: "get",
          url: `/textbook/textbookContents/${barcode}/${this.chapterNumber}/${idx}`,
          loadingBar: true,
        });

        if (res.status === 200) {
          //console.log(res.data);
          const sectionData = res.data;
          this.sectionType = sectionData.type; // sectionType 업데이트

          // 이미지 필드가 존재하는 경우 전체 경로로 변환
          if (sectionData.type === "quiz") {
            const questionType = sectionData.content?.questionType;

            if (questionType === "objective") {
              sectionData.content.options = sectionData.content.options.map((option) => ({
                ...option,
                option_image: this.getFullImageUrl(option.option_image, barcode),
              }));
            } else if (questionType === "subjective") {
              sectionData.content.image = this.getFullImageUrl(sectionData.content.image, barcode);
            }
          } else if (sectionData.type === "game") {
            const gameType = sectionData.content?.gameType;

            if (gameType === "flashcard") {
              sectionData.content.data = sectionData.content.data.map((card) => ({
                ...card,
                front_image: this.getFullImageUrl(card.front_image, barcode),
              }));
            } else if (gameType === "pair") {
              sectionData.content.data = sectionData.content.data.map((pair) => ({
                ...pair,
                definition_image: this.getFullImageUrl(pair.definition_image, barcode),
              }));
            } else if (gameType === "objective") {
              sectionData.content.options = sectionData.content.options.map((option) => ({
                ...option,
                option_image: this.getFullImageUrl(option.option_image, barcode),
              }));
            }
          }

          // 변환된 데이터를 적용
          this.currentSection = sectionData;
          //console.log(this.currentSection);
        }
      } catch (error) {
        console.error("Error loading section:", error);
      }
    },
    async deleteSection(idx) {
      
      let barcode = this.$route.query.barcode;
      const requestFn = async () => {
        return await this.$axios({
          method: "delete",
          url: `/textbook/editor/textbookContents/${barcode}/${this.chapterNumber}/${idx}`,
        });
      };

      // SweetAlert 확인창 + 요청 실행
      let result = await this.$showConfirmationAndSubmit(requestFn, null, {'title': this.$t('alert.06')});
      if (result === "success") {
        // 성공 메시지
        this.$notifyVue({ type: "success", title: this.$t('alert.09') });
        
        // 섹션 목록 새로고침
        this.chapertNumberChange(this.chapterNumber);

        // 선택된 섹션 초기화 → 기본값("text")으로 변경
        this.sectionNumber = null;
        this.sectionType = "text"; // 기본 컴포넌트로 변경
        this.currentSection = this.getDefaultSection("text");

      }
      
    },
    async onSubmit(values, { resetForm }) {
      let barcode = this.$route.query.barcode;

      if(barcode == null){
        this.$notifyVue({type: "error", title: this.$t('alert.27', { obj: this.$t('label.28') })});
        return
      }
      if(this.chapterNumber == null){
        this.$notifyVue({type: "error", title: this.$t('alert.27', { obj: this.$t('txt.14') })});
        return;
      }
      if (!this.currentSection.guideBook || this.currentSection.guideBook.length === 0) {
        delete this.currentSection.guideBook;
      }

      const formData = new FormData();

      // JSON 데이터를 문자열로 변환하여 추가
      const jsonData = JSON.stringify(this.currentSection);
      formData.append("json_data", jsonData);

      // 이미지 파일 추가 (여러 개 가능)
      const addImages = (items, key) => {
        if (items && Array.isArray(items)) {  // items가 존재하고 배열인지 확인
          items.forEach((item) => {
            if (item[key] instanceof File) {
              formData.append("images", item[key]);
            }
          });
        }
      };

      // 게임 유형별로 이미지가 포함될 수 있는 키 찾기
      if (this.currentSection.type === "game") {
        const gameType = this.currentSection.content?.gameType;

        if (gameType === "flashcard") {
          addImages(this.currentSection.content?.data, "front_image");
        } else if (gameType === "pair") {
          addImages(this.currentSection.content?.data, "definition_image");
        } else if (gameType === "objective") {
          addImages(this.currentSection.content?.options, "option_image");
        }
      } else if (this.currentSection.type === "quiz") {
        const questionType = this.currentSection.content?.questionType;

        if (questionType === "objective") {
          // 객관식 퀴즈일 경우, options의 option_image를 추가
          addImages(this.currentSection.content?.options, "option_image");
        } else if (questionType === "subjective") {
          // 주관식 퀴즈일 경우, image 필드를 추가
          if (this.currentSection.content.image instanceof File) {
            formData.append("images", this.currentSection.content.image);
          }
        }
        
      }

      //console.log("FormData 내용 출력:");
      //for (let pair of formData.entries()) {
      //  console.log(pair[0], pair[1]);
      //}
      const requestFn = async () => {
        if(!this.sectionNumber){ // 신규
          return await this.$axios({
            method: "post",
            url: `/textbook/editor/textbookContents/${barcode}/${this.chapterNumber}/addSections`,
            data: formData,
          });
        }else{ // 업데이트
          return await this.$axios({
            method: "patch",
            url: `/textbook/editor/textbookContents/${barcode}/${this.chapterNumber}/${this.sectionNumber}`,
            data: formData,
          });
        }
      };
      
      // SweetAlert 확인창 + 요청 실행
      let result = await this.$showConfirmationAndSubmit(requestFn, resetForm, {'title': this.$t('alert.47')});
      if (result === "success") {
        // 성공 메시지
        this.$notifyVue({
          title: this.$t("alert.18"),
          text: "Saved successfully",
          type: "success",
        });

        // **이 부분 추가 → 섹션을 저장한 후 신규 모드로 전환**
        this.sectionNumber = null;  // 섹션 번호 초기화
        this.sectionType = "text";  // 기본 타입 설정
        this.currentSection = this.getDefaultSection("text");  // 새 섹션으로 초기화

        // 섹션정보 다시 가져오기
        this.chapertNumberChange(this.chapterNumber);        
      }   
      
    },
    async sectionReSorting(updatedEvents) {
      this.sections = updatedEvents; // 자식 컴포넌트에서 전달된 데이터로 갱신      
      let barcode = this.$route.query.barcode;
      let sorting_data = updatedEvents
        .map(({ idx, sort }) => (idx !== sort ? { sectionNumber: idx, newSectionNumber: sort } : null))
        .filter(Boolean);
      //console.log(sorting_data);
      //return;
      const res = await this.$axios({
        method: "patch",
        url: `/textbook/editor/textbookContents/${barcode}/chapters/${this.chapterNumber}/sections/sort`,
        data: {
          sorting_data: sorting_data,
        },
      });

      if(res.status === 200){
        this.chapertNumberChange(this.chapterNumber);
      }
    },
    getDefaultSection(type) {
      switch (type) {
        case "text":
          return {
            type: "text",
            content: "",
            guideBook: [],
          };
        case "quiz":
          return {
            type: "quiz",
            content: {
              questionType: "objective",
              questionSubtype: "",
              questionText: "",
              options: [],
              correctAnswer: [],
            },
          };
        case "game":
          return {
            type: "game",
            content: {
              gameType: "flashcard",
              data: [],
            },
          };
        default:
          throw new Error(`Unknown section type: ${type}`);
      }
    },
    // 타입 변경 시 currentSection 업데이트
    onSectionTypeChange(newType) {
      this.sectionType = newType; // 타입 업데이트
      this.sectionNumber = null;  // 섹션 번호 초기화 (신규 모드)
      this.currentSection = this.getDefaultSection(newType); // 초기화
    },
  },
  mounted() {

    if(!this.$route.query.barcode){ // barcode가 없으면 목록으로 이동
      this.$router.push({ path: "textbooks" });          
    }
    this.getChapters();
  },
};
</script>
