<!--
=========================================================
* Vue Material Dashboard 2 PRO - v3.1.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <!-- notifications //-->
  <notifications position="bottom right" />
  <sidenav
    v-if="showSidenav"
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
  />
  <main
    class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden"
  >
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
    <app-footer v-show="showFooter" />
    <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script>
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: "App",
  components: {
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
  created() {
    //console.log(`환경 : ${process.env.NODE_ENV}`);
  },
};
</script>
<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(
    195deg,
    #66bb6a 0%,
    #43a047 100%
  ) !important;
}

/* 알림 전체 여백 및 스타일 */
.vue-notification {
  margin: 10px 20px; /* 알림 외부 여백 */
  padding: 15px; /* 알림 내부 여백 */
  border-radius: 8px; /* 둥근 모서리 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
}

/* 알림 제목과 본문 간 간격 */
.vue-notification .notification-title {
  font-size: 18px; /* 제목 폰트 크기 */
  margin-bottom: 8px; /* 제목과 본문 사이 간격 */
}

.vue-notification .notification-content {
  font-size: 16px; /* 본문 폰트 크기 */
  line-height: 1.5; /* 줄 간격 조정 */
  margin-top: 4px; /* 본문 위쪽 간격 */
}

/* 알림 컨테이너와 화면 경계 간 간격 */
.vue-notification-group {
  margin: 20px; /* 그룹 여백 */
}

/* 모달 오버레이 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 모달 컨테이너 스타일 */
.modal-container {
  position: relative; /* 닫기 버튼 위치를 조정하기 위한 기준 */
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  max-width: 600px;
  width: 100%;
}

/* 닫기 버튼 스타일 */
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1051;
}

.modal-close-btn:hover {
  color: #000;
}
</style>
