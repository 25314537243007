<!--
참고  : https://www.npmjs.com/package/jodit-vue

부모컴포넌트에서 사용예시 :

<template>
  <JoditEditor v-model="editorContent" :config="editorConfig" />
  <p>Editor Content:</p>
  <div>{{ editorContent }}</div>
</template>

<script>
import JoditEditor from '@/components/JoditEditor.vue'; // JoditEditor 컴포넌트 불러오기

export default {
  name: 'EditTextbook',
  components: { JoditEditor },
  data() {
    return {
      editorContent: '', // 에디터 초기 값
      editorConfig: {
        minHeight: 300, // 최소 높이
        height: 500, // 고정 높이
        maxHeight: 800, // 최대 높이
        readonly: false, // 읽기 전용 설정 해제
        toolbarSticky: false,
        uploader: {
          insertImageAsBase64URI: true, // 이미지를 Base64로 업로드
        },
      },
    };
  },
};
</script>

//-->
<template>
  <div>
    <JoditEditor v-model="content" :config="editorConfig" />
  </div>
</template>

<script>
import "jodit/build/jodit.min.css";
import { JoditEditor } from "jodit-vue";

export default {
  name: "JoditEditorComponent",
  components: { JoditEditor },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    config: {
      type: Object,
      default: () => ({}), // 기본값을 비우고, data()에서 병합
    },
  },
  emits: ["update:modelValue"],
  computed: {
    content: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  data() {
    return {
      editorConfig: Object.assign(
        {
          readonly: false,
          toolbarSticky: false,
          uploader: {
            insertImageAsBase64URI: true,
          },
          //buttons: "bold,italic,underline,strikethrough,ul,ol,align,undo,redo,source", // fullsize 제거
          removeButtons: ["fullsize"], // fullsize 버튼 제거
          disablePlugins: ["fullsize"], // fullsize 기능 비활성화
        },
        this.config // 부모에서 전달된 config가 있다면 덮어쓰기
      ),
    };
  },
};
</script>