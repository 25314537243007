<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">{{ itemsConfig.title }}</h6>
    </div>
    <div class="p-3 card-body border-radius-lg" ref="sortableContainer">
      <div
        v-if="events.length === 0"
        class="text-center"
        style="min-height: 50px;"
      >
        No data
      </div>
      <div
        v-else
        v-for="({ idx, sort, title }, index) in events"
        :key="idx" 
        class="d-flex sortable-item"
        :class="index !== 0 ? 'mt-4' : ''"
      >
        <!-- 아이콘 -->
        <div
          class="icon icon-shape shadow text-center"
          :class="`bg-gradient-dark`"
        >
          <i class="material-icons opacity-10 pt-1">auto_stories</i>
        </div>
        <!-- 내용 -->
        <div class="ms-3 flex-grow-1">
          <div class="numbers">
            <h6 class="mb-1 text-sm text-dark">
              {{ itemsConfig.label }} {{ sort }}
            </h6>
            <span class="text-sm" v-html="title" />
          </div>
        </div>
        <!-- 버튼 -->
        <div class="button-container">
          <a class="mb-0 btn btn-link pe-3 ps-0" @click="fnView(idx)" style="color:green">view</a>
          <a class="mb-0 btn btn-link pe-3 ps-0" @click="fnDelete(idx)">delete</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";

export default {
  name: "ItemsList",
  props: {
    itemsConfig: {
      type: Object,
      default: () => ({}),
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  emits: { "update:events": null, view: null },
  mounted() {
    Sortable.create(this.$refs.sortableContainer, {
      animation: 300,
      ghostClass: "sortable-ghost",
      onStart: (event) => {
        console.log("Drag started:", event);
      },
      onEnd: this.onDragEnd,
    });
  },
  methods: {
    onDragEnd(event) {
      // props 데이터를 수정하지 않기 위해 복사본 생성
      const updatedEvents = [...this.events];
      // 배열 순서 재조정
      const movedItem = updatedEvents[event.oldIndex];
      updatedEvents.splice(event.oldIndex, 1);
      updatedEvents.splice(event.newIndex, 0, movedItem);
      // sort 값을 1부터 재정렬
      updatedEvents.forEach((item, index) => {
        item.sort = index + 1;
      });
      // 부모 컴포넌트로 업데이트된 데이터를 전달
      this.$emit("update:events", updatedEvents);
      //console.log("Updated events:", updatedEvents);
    },
    fnView(idx) {
      this.$emit("view", idx);
    },
    fnDelete(idx){
      this.$emit("delete", idx);
    }
  },
};
</script>

<style scoped>
.sortable-item {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 좌측 아이콘과 내용 + 우측 버튼 간 여백 */
  margin-bottom: 10px;
  height: 50px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.ms-3 {
  flex-grow: 1; /* 내용이 공간을 채우도록 확장 */
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* 버튼을 맨 우측에 배치 */
  margin-left: auto; /* 자동으로 좌측 여백 추가 */
}

.sortable-ghost {
  opacity: 0.4;
}
</style>
