<template>
<div class="custom-select-wrapper">
    <label v-if="label" :for="id" class="form-label">{{ label }}</label>
    <select
    :id="id"
    ref="selectElement"
    :multiple="multiple"
    @blur="$emit('blur')" 
    >
    <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
    >
        {{ option.label }}
    </option>
    </select>
</div>
</template>

<script>
import Choices from "choices.js";

export default {
name: "CustomSelect",
props: {
    id: {
    type: String,
    required: true,
    },
    options: {
    type: Array,
    required: true,
    },
    modelValue: {
    type: [String, Number, Array], // Number도 허용
    default: null,
    },
    label: {
    type: String,
    default: "",
    },
    allowSearch: {
    type: Boolean,
    default: false,
    },
    placeholder: {
    type: String,
    default: "Select an option",
    },
    multiple: {
    type: Boolean,
    default: false,
    },
},
data() {
    return {
    choicesInstance: null,
    };
},
watch: {
    modelValue(newValue) {
    if (this.choicesInstance) {
        this.syncChoicesWithValue(newValue);
    }
    },
    options: {
    handler(newOptions) {
        if (this.choicesInstance) {
        this.choicesInstance.clearChoices();
        this.choicesInstance.setChoices(newOptions, "value", "label", true);
        }
    },
    deep: true,
    },
},
mounted() {
    this.initializeChoices();
},
beforeUnmount() {
    this.destroyChoices();
},
methods: {
    initializeChoices() {
    this.destroyChoices(); // 기존 인스턴스 제거
    const element = this.$refs.selectElement;

    this.choicesInstance = new Choices(element, {
        searchEnabled: this.allowSearch,
        placeholderValue: this.placeholder,
        shouldSort: false,
        removeItemButton: this.multiple,
        duplicateItemsAllowed: false,
    });

    // 초기 값 동기화
    this.syncChoicesWithValue(this.modelValue);

    // 값 변경 이벤트 리스너 추가
    element.addEventListener("change", this.onValueChange);
    },
    destroyChoices() {
    if (this.choicesInstance) {
        this.choicesInstance.destroy();
        this.choicesInstance = null;
    }
    },
    syncChoicesWithValue(value) {
    if (!this.choicesInstance) return;

    if (this.multiple && Array.isArray(value)) {
        this.choicesInstance.removeActiveItems();
        this.choicesInstance.setChoiceByValue(value);
    } else if (value) {
        this.choicesInstance.setChoiceByValue(value);
    }
    },
    onValueChange(event) {
    if (this.multiple) {
        const selectedValues = Array.from(event.target.selectedOptions).map(
        (option) => option.value
        );
        this.$emit("update:modelValue", selectedValues);
    } else {
        this.$emit("update:modelValue", event.target.value);
    }
    },
},
};
</script>

<style scoped>
.custom-select-wrapper {
width: 100%;
}
</style>
  