<template>
  <div>
    <!-- Game Type 선택 -->
    <CustomSelect
      id="gameType"
      label="Game Type"
      :options="gameTypeOptions"
      v-model="sectionData.content.gameType"
      @update:modelValue="onGameTypeChange"
      style="width: 30%;"
    />
    <hr style="color: green; height: 5px;" />

    <!-- Flashcard 타입 -->
    <div v-if="sectionData.content.gameType === 'flashcard'" class="game-section">
      <h6 class="mt-3">Flashcards</h6>
      <div v-for="(card, index) in sectionData.content.data" :key="index" class="game-item gameCard">
        <div class="row">
          <div class="col-8">
            <material-input
              :id="'flashcardFrontText' + index"
              variant="dynamic"
              label="Front Text"
              v-model="card.front_text"
              class="input-item"
            />
            <br />
            <material-input
              :id="'flashcardBackText' + index"
              variant="dynamic"
              label="Back Text"
              v-model="card.back_text"
              class="input-item"
            />
          </div>
          <div class="col-4">
            <image-upload
              :default-image="card.front_image || require('@/assets/img/no_image.png')"
              :unique-id="'flashcard-front-image-' + index"
              v-model="card.front_image"
            />
          </div>
        </div>
        <button @click="removeItem(index)" class="btn-remove mt-2">Remove</button>
      </div>
      <button @click.prevent="addFlashcard" class="btn btn-primary mt-2">Add Flashcard</button>
    </div>

    <!-- Pair 타입 -->
    <div v-if="sectionData.content.gameType === 'pair'" class="game-section">
      <h6 class="mt-3">Pairs</h6>
      <div v-for="(pair, index) in sectionData.content.data" :key="index" class="game-item gameCard">
        <div class="row">
          <div class="col-8">
            <material-input
              :id="'pairTermText' + index"
              variant="dynamic"
              label="Term Text"
              v-model="pair.term_text"
              class="input-item"
            />
            <br />
            <material-input
              :id="'pairDefinition' + index"
              variant="dynamic"
              label="Definition"
              v-model="pair.definition"
              class="input-item"
            />
          </div>
          <div class="col-4">
            <image-upload
              :default-image="pair.definition_image || require('@/assets/img/no_image.png')"
              :unique-id="'pair-definition-image-' + index"
              v-model="pair.definition_image"
            />
          </div>
        </div>
        <button @click="removeItem(index)" class="btn-remove mt-2">Remove</button>
      </div>
      <button @click.prevent="addPair" class="btn btn-primary mt-2">Add Pair</button>
    </div>

    <!-- Line 타입 -->
    <div v-if="sectionData.content.gameType === 'line'" class="game-section">
      <h6 class="mt-3">Line Matching</h6>
      <div v-for="(line, index) in sectionData.content.data" :key="index" class="game-item gameCard">
        <div class="row">
          <div class="col-6">
            <material-input
              :id="'lineLeft' + index"
              variant="dynamic"
              label="Left Text"
              v-model="line.left"
              class="input-item"
            />
          </div>
          <div class="col-6">
            <material-input
              :id="'lineRight' + index"
              variant="dynamic"
              label="Right Text"
              v-model="line.right"
              class="input-item"
            />
          </div>
        </div>
        <button @click="removeItem(index)" class="btn-remove mt-2">Remove</button>
      </div>
      <button @click.prevent="addLine" class="btn btn-primary mt-2">Add Line</button>
    </div>

    <!-- Objective 타입 -->
    <div v-if="sectionData.content.gameType === 'objective'" class="game-section">
      <h6 class="mt-3">Objective Question</h6>
      <div class="row">
        <div class="col-6">
          <material-input
            id="objectiveTitle"
            variant="dynamic"
            label="Title"
            v-model="sectionData.content.title"
            class="input-item"
          />
        </div>
        <div class="col-6">
          <material-input
            id="objectiveText"
            variant="dynamic"
            label="Text"
            v-model="sectionData.content.text"
            class="input-item"
          />
        </div>
      </div>

      <!-- Options -->
      <div v-for="(option, index) in sectionData.content.options" :key="index" class="game-item gameCard mt-4">
        <div class="row">
          <div class="col-8">
            <material-input
              :id="'optionText' + index"
              variant="dynamic"
              label="Option Text"
              v-model="option.option_text"
              class="input-item"
            />
          </div>
          <div class="col-4">
            <image-upload
              :default-image="option.option_image || require('@/assets/img/no_image.png')"
              :unique-id="'objective-option-image-' + index"
              v-model="option.option_image"
            />
          </div>
        </div>
        <button @click="removeOption(index)" class="btn-remove mt-2">Remove</button>
      </div>
      <button @click.prevent="addOption" class="btn btn-primary mt-2">Add Option</button>

      <div class="mt-3">
        <MultipleSelect
          id="correctAnswers"
          v-model="sectionData.content.correctAnswer"
          :options="correctAnswerOptions"
          placeholder="Correct Answers"
          :allow-search="true"
          :multiple="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineModel } from 'vue';
import MaterialInput from "@/components/MaterialInput.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";

const sectionData = defineModel({ type: Object, required: true });

const gameTypeOptions = [
  { value: "flashcard", label: "Flashcard" },
  { value: "pair", label: "Pair" },
  { value: "line", label: "Line" },
  { value: "objective", label: "Objective" },
];

const correctAnswerOptions = [
  { value: 1, label: "Option 1" },
  { value: 2, label: "Option 2" },
  { value: 3, label: "Option 3" },
  { value: 4, label: "Option 4" },
];

const onGameTypeChange = (newValue) => {
  sectionData.value.content = { gameType: newValue };

  if (["flashcard", "pair", "line"].includes(newValue)) {
    sectionData.value.content.data = [];
  }

  if (newValue === "objective") {
    sectionData.value.content.options = [];
    sectionData.value.content.correctAnswer = [];
  }
};

const addFlashcard = () => {
  sectionData.value.content.data.push({ front_text: "", front_image: "", back_text: "" });
};

const addPair = () => {
  sectionData.value.content.data.push({ term_text: "", definition: "", definition_image: "" });
};

const addLine = () => {
  sectionData.value.content.data.push({ left: "", right: "" });
};

const addOption = () => {
  sectionData.value.content.options.push({ option_text: "", option_image: "" });
};

const removeItem = (index) => {
  sectionData.value.content.data.splice(index, 1);
};

const removeOption = (index) => {
  sectionData.value.content.options.splice(index, 1);
};
</script>
<style>
.gameCard {
  background-color: #f5f5f5; border-radius: 10px; padding: 15px; margin-bottom: 10px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);border:1px solid gray
}

.btn-remove {
  background: none; /* 배경 제거 */
  border: none; /* 테두리 제거 */
  color: #dc3545; /* 텍스트 색상 (Bootstrap의 Danger 컬러) */
  font-size: 14px; /* 글자 크기 */
  cursor: pointer; /* 클릭 가능한 커서 */
  padding: 0; /* 여백 제거 */
}

.btn-remove:hover {
  text-decoration: underline; /* 호버 시 밑줄 추가 */
}
</style>