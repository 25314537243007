/* eslint-disable prettier/prettier */
import { createI18n } from "vue-i18n";

import english from "./english.json";
import korean from "./korean.json";
import spanish from "./spanish.json";
import chinese from "./chinese.json";
import japanese from "./japanese.json";

const messages = { english, korean, spanish, chinese, japanese };

// 언어 설정 로직
let locale = "";
let ls_lang = localStorage.getItem("lang");

if (ls_lang !== null) {
  locale = ls_lang;
} else {
  const browserLang = getLanguage();
  if (browserLang === "en-US" || browserLang === "en") {
    locale = "english";
  } else if (browserLang === "ko-KR" || browserLang === "ko") {
    locale = "korean";
  } else if (browserLang === "es-ES" || browserLang === "es") {
    locale = "spanish";
  } else if (["zh-CN", "zh-SG", "zh-TW", "zh"].includes(browserLang)) {
    locale = "chinese";
  } else if (browserLang === "ja-JP" || browserLang === "ja") {
    locale = "japanese";
  } else {
    locale = "english";
  }
}

const i18n = createI18n({
  legacy: false, // Composition API 방식 사용을 위해 legacy를 false로 설정
  locale,
  messages,
});

function getLanguage() {
  return navigator.language || navigator.userLanguage;
}

export default i18n;