<template>
  <navbar btn-background="btn-white" />
  <div
    class="page-header align-items-start min-height-300 m-3 border-radius-xl"
    :style="{ backgroundImage: `url(${backgroundImage})` }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
  </div>
  <div class="container mb-4">
    <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
      <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
        <div class="card mt-8">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1 text-center py-4"
            >
              <h4 class="font-weight-bolder text-white mt-1">FOR {{$CONF.APP_USERTYPE}}</h4>
              <p class="mb-1 text-sm text-white">
                Enter your email and password to Sign In
              </p>
            </div>
          </div>
          <div class="card-body">
            <!-- Vee-Validate Form -->
            <VeeForm
              :validation-schema="schema"
              class="text-start"
              @submit="onSubmit"
            >
              <div class="mb-4">
                <Field
                  v-slot="{ field, errors }"
                  name="email"
                >
                  <material-input
                    id="email"
                    v-bind="field"
                    type="email"
                    placeholder=""
                    name="email"
                    label="Email"
                    variant="static"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </Field>
              </div>
              <div class="mb-4">
                <Field
                  v-slot="{ field, errors }"
                  name="password"
                >
                  <material-input
                    id="password"
                    v-bind="field"
                    type="password"
                    placeholder=""
                    name="password"
                    label="Password"
                    variant="static"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  />
                  <ErrorMessage name="password" class="text-danger" />
                </Field>
              </div>
              <div class="text-center">
                <material-button
                  type="submit"
                  class="my-4 mb-2"
                  variant="gradient"
                  color="dark"
                  full-width
                  >Sign in</material-button
                >
              </div>
            </VeeForm>
            <div class="px-1 pt-0 text-center card-footer px-lg-2 mt-3">
              <p class="mx-auto mb-4 text-sm">
                {{$t('txt.07')}}
                <a href="https://www.corpuslab.io/contact" target="_blank" class="text-primary text-decoration-none font-weight-bolder">
                  {{$t('label.23')}}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import { Field, Form as VeeForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "SigninCover",
  components: {
    Navbar,
    AppFooter,
    MaterialInput,
    MaterialButton,
    Field,
    VeeForm,
    ErrorMessage,
  },
  data() {
    return {
      backgroundImage: require('@/assets/img/bg-pricing.jpg'),
      schema: yup.object({
        email: yup.string().email("Invalid email").required("Email is required"),
        password: yup
          .string()
          .min(6, "Password must be at least 6 characters")
          .required("Password is required"),
      }),
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    onSubmit(values) {
      //console.log("Form submitted with values:", values);
      // Login 액션실행
      this.$store.dispatch(this.$CONF.ACT_LOGIN, values);
    },
  },
};
</script>