<template>
  <div class="custom-select-wrapper">
    <label v-if="label">{{ label }}</label>
    <div class="custom-select-container">
      <select
        v-model="localValue"
        :multiple="multiple"
        class="no-border-select"
        @blur="$emit('blur')"
        @change="updateValue"
      >
        <option value="" disabled>{{ placeholder }}</option>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          v-html="option.label"
        />
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [String, Number, Array],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    updateValue() {
      this.$emit("update:modelValue", this.localValue); // this.localValue로 값 전달
    },
  },
};
</script>

<style scoped>
.custom-select-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

.form-label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

.custom-select-container {
  position: relative;
  width: 100%;
}

.no-border-select {
  width: 100%;
  padding: 0.625rem 0;
  font-size: 1rem;
  color: #495057;
  background-color: transparent;
  border: none; /* 테두리 제거 */
  border-bottom: 1px solid #ced4da; /* 하단에만 선 추가 */
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.no-border-select:focus {
  border-bottom: 1px solid #80bdff; /* 포커스 시 하단 색 변경 */
}

.no-border-select option {
  font-size: 1rem;
  color: #495057;
}
</style>
