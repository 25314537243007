<!--
사용예 : value 사용
<material-input
  id="publisher"
  variant="dynamic"
  :label="$t('label.29')"
  :value="publisher"
  @update:value="publisher = $event"
/>

사용예 : v-model 사용
<material-input
  id="publisher"
  variant="dynamic"
  :label="$t('label.29')"
  v-model="publisher"
/>
//-->
<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <!-- 파일 타입일 경우 label은 별도로 표시 -->
    <template v-if="type === 'file'">
      <label :for="id" class="form-label">
        {{ label }}
      </label>
      <div class="custom-file-wrapper">
        <input
          :id="id"
          type="file"
          class="custom-file-input"
          @change="onFileChange"
        />
        <label class="custom-file-label" :for="id">
          {{ fileName || "파일을 선택하세요" }}
        </label>
      </div>
    </template>

    <!-- 기본 타입 처리 -->
    <template v-else>
      <label
        :for="id"
        class="form-label"
        :class="{ 'form-label-floating': internalValue || isFocused }"
      >
        {{ label }}
      </label>
      <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size)"
        :name="name"
        :value="internalValue"
        :placeholder="placeholder"
        :required="isRequired"
        :disabled="disabled"
        @input="onInput"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </template>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialInput",
  data() {
    return {
      isFocused: false,
      internalValue: this.modelValue ?? this.value, // ✅ 기존 value, modelValue 둘 다 지원
      fileName: null, // 파일 입력 시 파일명 저장
    };
  },
  props: {
    modelValue: { // ✅ v-model 지원
      type: [String, Number, File, Array],
      default: "",
    },
    value: { // ✅ 기존 방식도 유지
      type: [String, Number, File, Array],
      default: "",
    },
    variant: { type: String, default: "outline" },
    label: { type: String, default: "" },
    size: { type: String, default: "default" },
    success: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    name: { type: String, default: "" },
    id: { type: String, required: true },
    placeholder: { type: String, default: "" },
    type: { type: String, default: "text" },
    isRequired: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "update:value", "file-change"], // ✅ 기존 방식 & v-model 업데이트
  watch: {
    value(newVal) { // ✅ 기존 value 감지
      this.internalValue = newVal;
    },
    modelValue(newVal) { // ✅ v-model 감지
      this.internalValue = newVal;
    },
  },
  mounted() {
    setMaterialInput();
  },
  methods: {
    onInput(event) {
      const newValue = event.target.value;
      this.internalValue = newValue;
      this.$emit("update:modelValue", newValue); // ✅ v-model 업데이트
      this.$emit("update:value", newValue); // ✅ 기존 방식 업데이트
    },
    onFileChange(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.fileName = files[0].name;
        this.$emit("file-change", files);
      } else {
        this.fileName = null;
      }
    },
    getClasses(size) {
      return size ? `form-control-${size}` : null;
    },
    getStatus(error, success) {
      if (success) return "is-valid";
      if (error) return "is-invalid";
      return null;
    },
  },
};
</script>

<style scoped>
/* 기본 입력 스타일 */
.form-label-floating {
  position: absolute;
  top: -15px !important;
}

/* 커스텀 파일 입력 스타일 */
.custom-file-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-file-input {
  display: none;
}

.custom-file-label {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  color: #495057;
  text-align: left;
  cursor: pointer;
}
</style>
