<template>
    <div>
        <label for="content">{{ $t('label.39') }}</label>
        <!-- QuillEditor 으로 변경하려면 주석제거하고 JoditEditor를 주석
        <QuillEditor 
            id="content"
            v-model="sectionData.content" 
            :config="{ htmlView: true, minHeight: '500px' }" 
        />-->
        <JoditEditor 
            id="content"
            v-model="sectionData.content" 
            :config="editorConfig"
        />
        <div class="mt-3">
        <h6>Guide Book</h6>
        <div v-for="(guide, index) in sectionData.guideBook" :key="index" class="mb-2">
            <br/>
            <material-input
                :id="'guideTitle' + index"
                variant="dynamic"
                label="Guide Title"
                v-model="guide.title"
                style="width: 50%;"
            />
            <!--<label :for="'guideContent' + index">Content</label>-->
            <br/>
            <QuillEditor 
                :id="'guideContent' + index"
                v-model="guide.content" 
                :config="{ htmlView: false, minHeight: '100px' }" 
            />
        </div>
        <button @click.prevent="addGuideBook" class="btn btn-primary mt-2">Add Guide Book</button>
        </div>
    </div>
</template>

<script setup>
import { defineModel } from 'vue';
import QuillEditor from "@/components/QuillEditor.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import JoditEditor from '@/components/JoditEditor.vue';

// `defineModel()`에서 name을 지정하지 않으면 자동으로 `v-model`과 연결됨
const sectionData = defineModel({ type: Object, required: true });

const editorConfig = {
    minHeight: 500, // 최소 높이
};

// Guide Book 추가 함수
const addGuideBook = () => {
    if (!sectionData.value.guideBook) {
        sectionData.value.guideBook = []; // guideBook 속성이 없으면 빈 배열로 초기화
    }
    sectionData.value.guideBook.push({ title: "", content: "" });
};
</script>
