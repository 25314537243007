import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Sales from "../views/dashboards/Sales.vue";
import Overview from "../views/pages/profile/Overview.vue";
import Projects from "../views/pages/profile/Projects.vue";
import Timeline from "../views/pages/projects/Timeline.vue";
import Pricing from "../views/pages/Pricing.vue";
import RTL from "../views/pages/Rtl.vue";
import Charts from "../views/pages/Charts.vue";
import Notifications from "../views/pages/Notifications.vue";
import Kanban from "../views/applications/Kanban.vue";
import Wizard from "../views/applications/wizard/Wizard.vue";
import DataTables from "../views/applications/DataTables.vue";
import Calendar from "../views/applications/Calendar.vue";
import NewProduct from "../views/ecommerce/products/NewProduct.vue";
import EditProduct from "../views/ecommerce/products/EditProduct.vue";
import ProductPage from "../views/ecommerce/products/ProductPage.vue";
import OrderDetails from "../views/ecommerce/orders/OrderDetails";
import OrderList from "../views/ecommerce/orders/OrderList";
import NewUser from "../views/pages/users/NewUser.vue";
import Settings from "../views/pages/account/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import Widgets from "../views/pages/Widgets.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";

// 내가 만든 페이지
import Textbooks from "@/views/copyright/Textbooks.vue";
import EditTextbook from "@/views/copyright/EditTextbook.vue";
import EditTextbookContents from "@/views/copyright/EditTextbookContents.vue";

import store from "@/store"; // Vuex store를 가져옵니다.

const routes = [
  {
    path: "/",
    redirect: "/dashboards/dashboard-default",
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    children: [
      {
        path: "dashboard-default",
        name: "Default",
        component: Default,
      },
      {
        path: "sales",
        name: "Sales",
        component: Sales,
      },
    ],
  },  
  {
    path: "/pages",
    name: "Pages",
    children: [
      {
        path: "profile/overview",
        name: "Profile Overview",
        component: Overview,
      },
      {
        path: "profile/projects",
        name: "All Projects",
        component: Projects,
      },
      {
        path: "projects/timeline",
        name: "Timeline",
        component: Timeline,
      },
      {
        path: "pricing-page",
        name: "Pricing Page",
        component: Pricing,
      },
      {
        path: "rtl-page",
        name: "RTL",
        component: RTL,
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
      },
      {
        path: "widgets",
        name: "Widgets",
        component: Widgets,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "users/new-user",
        name: "New User",
        component: NewUser,
      },
      {
        path: "account/settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "account/billing",
        name: "Billing",
        component: Billing,
      },
      {
        path: "account/invoice",
        name: "Invoice",
        component: Invoice,
      },
    ],
  },
  {
    path: "/applications",
    name: "Applications",
    children: [
      {
        path: "kanban",
        name: "Kanban",
        component: Kanban,
      },
      {
        path: "wizard",
        name: "Wizard",
        component: Wizard,
      },
      {
        path: "data-tables",
        name: "Data Tables",
        component: DataTables,
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
      },
    ],
  },
  {
    path: "/ecommerce",
    name: "Ecommerce",
    children: [
      {
        path: "products/new-product",
        name: "New Product",
        component: NewProduct,
      },
      {
        path: "products/edit-product",
        name: "Edit Product",
        component: EditProduct,
      },
      {
        path: "products/product-page",
        name: "Product Page",
        component: ProductPage,
      },
      {
        path: "orders/order-details",
        name: "Order Details",
        component: OrderDetails,
      },
      {
        path: "orders/order-list",
        name: "Order List",
        component: OrderList,
      },
    ],
  },
  {
    path: "/authentication",
    name: "Authentication",
    children: [
      {
        path: "signin/basic",
        name: "Signin Basic",
        component: Basic,
      },
      {
        path: "signin",
        name: "Signin Cover",
        component: Cover,
      },
      {
        path: "signin/illustration",
        name: "Signin Illustration",
        component: Illustration,
      },
      {
        path: "reset/cover",
        name: "Reset Cover",
        component: ResetCover,
      },
      {
        path: "signup",
        name: "Signup Cover",
        component: SignupCover,
      },
      {
        path: "/logout",
        name: "Logout",
        beforeEnter(to, from, next) {
          //console.log("로그아웃 시작");
          store
            .dispatch("auth/logout") // Vuex Store에서 로그아웃 액션 호출
            .then(() => {
              //console.log("로그아웃 완료");
            })
            .catch((err) => {
              console.error("로그아웃 중 오류 발생:", err);
              next(false); // 오류 발생 시 이동 중단
            });
        },
      }      
    ],
  },
  // 내가 새롭게 만든 라우트
  {
    path: "/account",
    name: "Account",
    children: [
      {
        path: "billing",
        name: "Sale Billing",
        component: Billing,
      },
    ]
  },
  {
    path: "/copyright",
    name: "Copyright",
    children: [
      {
        path: "textbooks",
        name: "All Textbooks",
        component: Textbooks,
      },
      {
        path: "editTextbook",
        name: "Edit Textbook",
        component: EditTextbook,
      },
      {
        path: "editTextbookContents",
        name: "Edit TextbookContents",
        component: EditTextbookContents,
      },
    ]
  },
  {
    path: "/helpDesk",
    name: "Help Desk",
    children: [
      {
        path: "notice",
        name: "Notice",
        component: DataTables,
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
