<template>
  <div>
    <!-- Quiz Type 선택 -->
    <CustomSelect
      id="questionTypeSelect"
      label="Question Type"
      :options="questionTypeOptions"
      v-model="sectionData.content.questionType"
      @update:modelValue="onQuestionTypeChange"
      style="width: 30%;"
    />
    <hr style="color: blue; height: 3px;" />

    <!-- 객관식 (Objective) -->
    <div v-if="sectionData.content.questionType === 'objective'" class="quiz-section">
      <h6 class="mt-3">Objective Question</h6>
      <material-input
        id="objectiveQuestionText"
        variant="dynamic"
        label="Question Text"
        v-model="sectionData.content.questionText"
        class="input-item"
      />
      <div v-for="(option, index) in sectionData.content.options" :key="index" class="quiz-item gameCard mt-4">
        <div class="row mt-3">
          <div class="col-8">
            <material-input
              :id="'objectiveOptionText-' + index"
              variant="dynamic"
              label="Option Text"
              v-model="option.option_text"
              class="input-item"
            />
          </div>
          <div class="col-4">
            <image-upload
              :default-image="option.option_image || require('@/assets/img/no_image.png')"
              :unique-id="'objective-option-image-' + index"
              v-model="option.option_image"
            />
          </div>
        </div>
        <button @click="removeOption(index)" class="btn-remove mt-2">Remove Option</button>
      </div>
      <button @click.prevent="addOption" class="btn btn-primary mt-2">Add Option</button>

      <!-- Correct Answers -->
      <div class="mt-3">
        <label>Correct Answers</label>
        <MultipleSelect
          id="correctAnswersSelect"
          v-model="sectionData.content.correctAnswer"
          :options="correctAnswerOptions"
          placeholder="Correct Answers"
          :allow-search="true"
          :multiple="true"
        />
      </div>
    </div>

    <!-- 주관식 (Subjective) -->
    <div v-if="sectionData.content.questionType === 'subjective'" class="quiz-section gameCard">
      <h6 class="mt-3">Subjective Question</h6>
      <CustomSelect
        id="subjectiveSubtypeSelect"
        label="Question Subtype"
        :options="subjectiveSubtypeOptions"
        v-model="sectionData.content.questionSubtype"
        style="width: 30%;"
      />
      <material-input
        id="subjectiveQuestionText"
        variant="dynamic"
        label="Question Text"
        v-model="sectionData.content.questionText"
        class="input-item mt-3"
      />
      <div v-if="sectionData.content.questionSubtype === 'short'" class="mt-5">
        <div class="row">
          <div class="col-8 col-sm-8">
            <material-input
              id="shortPlaceholderInput"
              variant="dynamic"
              label="Placeholder"
              v-model="sectionData.content.placeholder"
              class="input-item"
            />
            <material-input
              id="shortHintInput"
              variant="dynamic"
              label="Hint"
              v-model="sectionData.content.hint"
              class="input-item"
            />
            <material-input
              id="shortAnswerInput"
              variant="dynamic"
              label="Answer"
              v-model="sectionData.content.answer"
              class="input-item"
            />
          </div>
          <div class="col-4 col-sm-4">
            <image-upload
              :default-image="sectionData.content.image || require('@/assets/img/no_image.png')"
              :unique-id="'subjective-short-image'"
              v-model="sectionData.content.image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineModel } from 'vue';
import MaterialInput from "@/components/MaterialInput.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";

// `defineModel()`을 사용하여 v-model을 자동으로 연결
const sectionData = defineModel({ type: Object, required: true });

const questionTypeOptions = [
  { value: "objective", label: "Objective" },
  { value: "subjective", label: "Subjective" },
];

const subjectiveSubtypeOptions = [
  { value: "short", label: "Short Answer" },
  { value: "long", label: "Long Answer" },
];

const correctAnswerOptions = [
  { value: 1, label: "Option 1" },
  { value: 2, label: "Option 2" },
  { value: 3, label: "Option 3" },
  { value: 4, label: "Option 4" },
];

// 문제 유형 변경 시 데이터 초기화
const onQuestionTypeChange = (newValue) => {
  sectionData.value.content = {
    questionType: newValue,
    questionText: "",
    ...(newValue === "objective"
      ? { options: [], correctAnswer: [] }
      : newValue === "subjective"
      ? { questionSubtype: "short", placeholder: "", hint: "", image: null, answer: "" }
      : {}),
  };
};

// 객관식 옵션 추가
const addOption = () => {
  sectionData.value.content.options.push({ option_text: "", option_image: "" });
};

// 객관식 옵션 제거
const removeOption = (index) => {
  sectionData.value.content.options.splice(index, 1);
};
</script>

<style scoped>
.quiz-section {
  margin-top: 20px;
}
.quiz-item {
  margin-bottom: 15px;
}
.gameCard {
  background-color: #f5f5f5; border-radius: 10px; padding: 15px; margin-bottom: 10px; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); border:1px solid gray
}

.btn-remove {
  background: none; /* 배경 제거 */
  border: none; /* 테두리 제거 */
  color: #dc3545; /* 텍스트 색상 (Bootstrap의 Danger 컬러) */
  font-size: 14px; /* 글자 크기 */
  cursor: pointer; /* 클릭 가능한 커서 */
  padding: 0; /* 여백 제거 */
}

.btn-remove:hover {
  text-decoration: underline; /* 호버 시 밑줄 추가 */
}
</style>
