<template>
  <component
    :is="to ? 'router-link' : 'a'"
    :to="to"
    :href="!to ? (collapse ? `#${collapseRef}` : collapseRef) : undefined"
    :data-bs-toggle="collapse && !to ? 'collapse' : ''"
    :aria-controls="collapseRef"
    aria-expanded="false"
    class="nav-link"
    v-bind="$attrs"
    @click="handleClick"
    :style="{ cursor: 'pointer' }"
  >
    <div
      class="text-center d-flex align-items-center justify-content-center"
      :class="isRTL ? ' ms-2' : 'me-2'"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">{{ navText }}</span>
  </component>
  <div v-if="!to" :id="collapseRef" class="collapse">
    <slot name="list"></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SidenavCollapse",
  props: {
    collapseRef: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      default: "",
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    to: {
      type: [String, Object], // to 속성으로 router-link의 라우팅 정보 전달
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    handleClick() {
      if (!this.to && this.collapse) {
        this.isExpanded = !this.isExpanded;
      }
    },
  },
};
</script>
