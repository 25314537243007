<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="brooklynAlice"
          :nav-text="userInfo.name"
          :class="getRoute() === 'brooklynAlice' ? 'active' : ''"
        >
          <template #icon>
            <img src="../../assets/img/person.png" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Profile Overview' }"
                mini-icon="MP"
                :text="$t('label.26')"
              />
              <sidenav-item
                :to="{ name: 'Settings' }"
                mini-icon="S"
                :text="$t('label.25')"
              />
              <sidenav-item
                :to="{ name: 'Logout' }"
                mini-icon="L"
                :text="$t('label.24')"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="dashboardsExamples"
          :nav-text="$t('navi.dash')"
          :to="{ name: 'Sales' }"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>
      <!--<li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li>-->
      <!-- 페이지 //-->
      <li v-for="(menu, index) in itemsMenu" :key="index" class="nav-item">
        <sidenav-collapse
          :collapse-ref="menu.collapseRef"
          :nav-text="menu.navText"
          :class="{ active: getRoute() === menu.collapseRef }"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">{{ menu.icon }}</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- 1단계 메뉴 반복 -->
              <template v-for="(item, idx) in menu.items" :key="idx">
                <sidenav-item
                  v-if="!item.children"
                  :to="item.to"
                  :mini-icon="item.miniIcon"
                  :text="item.text"
                  :class="{ active: getRoute() === item.to.name }"
                />
                <sidenav-collapse-item
                  v-else
                  :refer="item.refer"
                  :mini-icon="item.miniIcon"
                  :text="item.text"
                  :class="{ active: item.children.some(child => getRoute() === child.to.name) }"
                >
                  <template #nav-child-item>
                    <sidenav-item
                      v-for="(child, childIdx) in item.children"
                      :key="childIdx"
                      :to="child.to"
                      :mini-icon="child.miniIcon"
                      :text="child.text"
                      :class="{ active: getRoute() === child.to.name }"
                    />
                  </template>
                </sidenav-collapse-item>
              </template>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem
  },
  data() {
    return {
      itemsMenu: [
        {
          collapseRef: "account",
          navText: this.$t('navi.02'),
          icon: "calculate",
          items: [
            {
              refer: "Sale",
              miniIcon: "S",
              text: this.$t('navi.02_02'),
              children: [
                { to: { name: "Sale Billing" }, miniIcon: "SB", text: this.$t('navi.02_02_01') },
                { to: { name: "" }, miniIcon: "SB", text: this.$t('navi.02_02_02') },
                { to: { name: "" }, miniIcon: "SB", text: this.$t('navi.02_02_03') },
              ],
            }
          ],
        },
        {
          collapseRef: "copyright",
          navText: this.$t('navi.01'),
          icon: "copyright",
          items: [
            { to: { name: "All Textbooks" }, miniIcon: "T", text:  this.$t('navi.01_01') },
            { to: { name: "" }, miniIcon: "K", text:  this.$t('navi.01_02') },
          ],
        },
        {
          collapseRef: "helpDesk",
          navText: this.$t('navi.04'),
          icon: "help",
          items: [
            { to: { name: "" }, miniIcon: "T", text:  this.$t('navi.04_01') },
            { to: { name: "Notice" }, miniIcon: "N", text:  this.$t('navi.04_02') },
            { to: { name: "Notice" }, miniIcon: "Q", text:  this.$t('navi.04_03') },
            { to: { name: "" }, miniIcon: "K", text:  this.$t('navi.04_04') },
          ],
        },        
      ],
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    userInfo() {
      return this.$store.state.auth.user_info || {}; // 기본값 처리
    },
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      //console.log(routeArr[1]);
      return routeArr[1];
    }
  }
};
</script>
