<template>
  <div class="image-upload-component">
    <!-- 이미지 미리보기 -->
    <div class="preview-wrapper">
      <input
        :id="uniqueId"
        type="file"
        hidden
        @change="onFileChange"
        ref="fileInput"
      />
      <label :for="uniqueId" style="cursor: pointer;">
        <img
          :src="preview || defaultImageSrc"
          class="img-fluid shadow border-radius-lg"
          alt="Uploaded Image"
        />
      </label>
    </div>

    <!-- 이미지 이름 표시 및 삭제 -->
    <div class="file-info mt-2" v-if="fileName">
      <p>{{ fileName }}</p>
      <i class="material-icons text-lg me-2" style="color: red;cursor: pointer;"  @click="clearFile">delete</i>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  props: {
    defaultImage: {
      type: [String, File],
      default: require("@/assets/img/no_image.png"),
    },
    modelValue: {
      type: [String, File, null],
      default: null,
    },
    uniqueId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      preview: null,
      fileName: null,
    };
  },
  computed: {
    defaultImageSrc() {
      if (this.defaultImage instanceof File) {
        return URL.createObjectURL(this.defaultImage); // File 객체를 Blob URL로 변환
      }
      return this.defaultImage;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue instanceof File) {
          this.createPreview(newValue);
        } else {
          this.preview = newValue || this.defaultImageSrc;
        }
      },
    },
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;
        this.createPreview(file);
        this.$emit("update:modelValue", file);

        // 동일 파일 재업로드를 위해 input 값을 초기화
        this.$refs.fileInput.value = null;
      }
    },
    createPreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.preview = e.target.result; // Base64로 변환된 이미지
      };
      reader.readAsDataURL(file);
    },
    clearFile() {
      this.preview = this.defaultImageSrc;
      this.fileName = null;
      this.$emit("update:modelValue", null); // 부모 컴포넌트에 파일 초기화 알림
    },
  },
};
</script>

<style scoped>
.image-upload-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px dashed #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
}
.preview-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.file-info {
  text-align: center;
}
</style>
