import i18n from "@/lang/lang"; // lang.js에서 i18n 가져오기

/**
 * 헬퍼 함수: 언어 설정 및 로컬 스토리지 업데이트
 */
function setLanguage(payload) {
  i18n.global.locale.value = payload; // 글로벌 i18n locale 변경
  localStorage.setItem("lang", payload); // 로컬 스토리지 업데이트
}

/**
 * initial state
 */
const state = {
  lang: localStorage.getItem("lang") || "english", // 기본 언어 설정
};

/**
 * getters
 */
const getters = {
  getLanguage(state) {
    return state.lang;
  },
};

/**
 * mutations
 */
const mutations = {
  changeLanguage(state, payload) {
    state.lang = payload;
    setLanguage(payload); // 헬퍼 함수 호출
  },
};

/**
 * actions
 */
const actions = {
  changeLanguage({ commit }, payload) {
    commit("changeLanguage", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
