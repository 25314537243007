import { createApp } from "vue";
import App from "./App.vue";
import store from "./store"; // vuex
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import i18n from "./lang/lang.js"; // Language 설정
import CONF from "./Config.js"; // 시스템 구성정보
import common from "@/plugins/common.js";
import axios from "axios";
import Notifications from '@kyvg/vue3-notification';

// https://www.npmjs.com/package/vue-loading-overlay
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { Field, Form as VeeForm, ErrorMessage, defineRule } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// vee-validate 규칙 정의
defineRule("required", required);
defineRule("email", email);
defineRule("min", min);

// 앱 인스턴스 생성
const app = createApp(App);

// 글로벌 프로퍼티 설정 (CONF와 같은 전역 설정)
app.config.globalProperties.$CONF = CONF;
// Axios를 글로벌 프로퍼티로 추가
app.config.globalProperties.$axios = axios;

// vee-validate 컴포넌트 전역 등록
app.component("Field", Field);
app.component("MyForm", VeeForm);
app.component("ErrorMessage", ErrorMessage);

// 플러그인 사용
app.use(store);
app.use(router);
app.use(i18n);
app.use(VueSweetalert2);
app.use(MaterialDashboard);
app.use(common); // Vue에서 this.메소드명 으로 사용
app.use(LoadingPlugin);
app.use(Notifications);

app.directive('tooltip', {
    mounted(el, binding) {
        tippy(el, {
            content: binding.value,
            placement: 'top',
            animation: 'fade',
        });
    },
});

/* Vuex Store에 Router를 주입
    아래의 방식으로 vuex에서 router를 사용할 수 있다
    this.$router
*/
store.$router = router;

// 라우터 전역 가드 설정
router.beforeEach(async (to, from, next) => {
    const exceptArr = ["/authentication/signin", "/authentication/signup"];
    const checkVal = exceptArr.includes(to.path) ? "Y" : "N";

    // 비로그인 상태에서 보호된 페이지에 접근 시
    if (checkVal === "N") {
        if (store.state.auth.is_login) {
            return next(); // 로그인 상태면 이동 허용
        }

        // 로그인하지 않았으면 토큰 확인 및 사용자 정보 로드
        const token = localStorage.getItem("access_token");
        if (!token || token === "null") {
            return next({
                path: "/authentication/signin",
                query: { redirect: to.fullPath },
            });
        }

        try {
            // 사용자 정보 가져오기
            await store.dispatch("auth/getMemberInfo");
            next(); // 사용자 정보 로드 완료 후 이동 허용
        } catch (err) {
            console.error("Failed to fetch user info:", err);
            next({
                path: "/authentication/signin",
                query: { redirect: to.fullPath },
            });
        }
    } else {
        next(); // 예외 페이지는 바로 이동 허용
    }
});

// 앱 마운트
app.mount("#app");

// 운영 모드 설정
if (process.env.NODE_ENV === "production") {
    app.config.devtools = false;
    app.config.debug = false;
    app.config.silent = true;
}