<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">{{$t('txt.14')}}</h5>
    <p class="mb-0 text-sm">{{$t('txt.15')}}</p>
    <div class="multisteps-form__content">

      <VeeForm
        ref="formRef"
        :validation-schema="schema"
        @submit="onSubmit"
      >
      <div class="row mt-3">
        <!-- 좌측 //-->
        <div class="col-12 col-sm-4">
          
          <div class="row" >
            <div class="mt-4 col-12">
              <List
                style="border:1px solid gray;overflow:auto; max-height: 500px;"
                :items-config="itemsConfig"
                :events="chapters"
                @update:events="handleUpdateEvents"
                @view="loadChapter"
                @delete="deleteChapter"
              />
            </div>
          </div>
        </div>

        <!-- 우측 //-->
        <div class="col-12 col-sm-8">
          <div class="row">
            <div class="col-4 col-sm-4">
              <Field v-slot="{ field }" name="theme">
                <CustomSelect
                  :label="$t('label.37')"
                  :options="themeOptions"
                  :model-value="field.value"
                  placeholder="Select Theme"
                  @update:modelValue="field.onChange"
                  @blur="field.onBlur"
                />
                <ErrorMessage name="theme" class="text-danger" />
              </Field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mt-4 ms-0">{{ $t('label.14') }}</label>
              <div>
                <QuillEditor v-model="title" :config="editorConfig" />
              </div>
            </div>
          </div>
        </div>
      </div>     
      <div class="button-row d-flex mt-4">
        <!--<material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="$parent.nextStep"
          >Next</material-button
        >-->
        <material-button
          type="submit"
          class="ms-auto mb-0 js-btn-next"
          variant="gradient"
          color="dark"
          >{{$t("btn.15")}}</material-button
        >
      </div>
        
      </VeeForm>
    </div>
  </div>
</template>

<script>
//import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import List from "@/components/List.vue";
import QuillEditor from "@/components/QuillEditor.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import { Field, Form as VeeForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "UserInfo",
  components: {
    //MaterialInput,
    MaterialButton,
    List,
    QuillEditor,
    CustomSelect,
    Field, VeeForm, ErrorMessage,
  },
  data(){
    return {
      chapterNumber: null,
      theme: null,
      title: null,
      themeOptions: [],
      chapters: [],
      itemsConfig: {
        title: "Chapters",
        label: "Chapter",
      },
      editorConfig: {
        htmlView: false,
        minHeight: "100px",
        toolbarOptions: [
          ["bold", "italic"],
          [{ header: [1, 2, 3, false] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
        ],
      },
      schema: yup.object({
        theme: yup
          .string().required(this.$t('alert.71', { obj: this.$t('label.37') }))
      }),
    }
  },
  methods: {
    async getThemes() {
      try {
        const res = await this.$axios({
          method: "get",
          url: "/common/themes",
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data)) {
          this.themeOptions = res.data;
        } else {
          console.error("Unexpected response format:", res.data);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    async getChapters() {

      let barcode = this.$route.query.barcode;
      try {
        const res = await this.$axios({
          method: "get",
          url: `/textbook/textbookContents/${barcode}`,
          loadingBar: true,
        });

        if (res.status === 200 && Array.isArray(res.data.chapters)) {
          this.chapters = res.data.chapters.map(item => {
            return {
              idx: item.chapterNumber,
              sort: item.chapterSort,
              title: item.title,
              theme: item.theme,
            };
          });
          //console.log(this.chapters);
        }
      } catch (error) {
        console.error("Error :", error);
      }
    },
    async loadChapter(idx) {
      let chapter = this.chapters.find((item) => item.idx === idx);
      if (!chapter) {
        console.error("Chapter not found for idx:", idx);
        return;
      }

      this.chapterNumber = chapter.idx;
      this.title = chapter.title;
      this.theme = chapter.theme;

      // VeeValidate 폼에 데이터 반영
      this.$refs.formRef.setValues({
        theme: this.theme,
        title: this.title,
      });
    },
    async deleteChapter(idx){
      let barcode = this.$route.query.barcode;
      const requestFn = async () => {
        return await this.$axios({
          method: "delete",
          url: `/textbook/editor/textbookContents/${barcode}/${idx}`,
        });
      };

      // SweetAlert 확인창 + 요청 실행
      let result = await this.$showConfirmationAndSubmit(requestFn, null, {'title': this.$t('alert.06')});
      if (result === "success") {
        // 성공 메시지
        this.$notifyVue({ type: "success", title: this.$t('alert.09') });
        this.getChapters();
      }

    },
    async onSubmit(values, { resetForm }) {
      //console.log(values);
      let barcode = this.$route.query.barcode;
      const requestFn = async () => {
        if(!this.chapterNumber){ // 신규

          return await this.$axios({
            method: "put",
            url: `/textbook/editor/textbookContents/${barcode}/addChapters`,
            data: {
              theme: values.theme,
              title: this.title
            },
          });
        }else{ // 업데이트
          return await this.$axios({
            method: "patch",
            url: `/textbook/editor/textbookContents/${barcode}/${this.chapterNumber}`,
            data: {
              theme: values.theme,
              title: this.title
            },
          });
        }        
      };

      // SweetAlert 확인창 + 요청 실행
      let result = await this.$showConfirmationAndSubmit(requestFn, resetForm, {'title': this.$t('alert.47')});
      if (result === "success") {
        // 성공 메시지
        this.$notifyVue({
          title: this.$t("alert.18"),
          text: "Saved successfully",
          type: "success",
        });

        // 챕터정보 다시 가져오기
        this.getChapters();

        // 입력값 초기화
        this.title = null;
        this.theme = null;
      }      
    },
    async handleUpdateEvents(updatedEvents) {
      this.chapters = updatedEvents; // 자식 컴포넌트에서 전달된 데이터로 갱신
      
      let barcode = this.$route.query.barcode;
      await this.$axios({
        method: "patch",
        url: `/textbook/editor/textbookContents/${barcode}/update-sorting`,
        data: {
          chapters: updatedEvents.map(({ idx, sort }) => ({
            chapterNumber: idx,
            chapterSort: sort,
          })),
        },
      });
    },
  },
  mounted() {

    if(!this.$route.query.barcode){ // barcode가 없으면 목록으로 이동
      this.$router.push({ path: "textbooks" });          
    }
    this.getThemes();
    this.getChapters();
  },
};
</script>
