import swal from "sweetalert2";
import axios from "axios";
import { useLoading } from "vue-loading-overlay";

/* eslint-disable prettier/prettier */
const common = {
  install(app) {
    /**
     * 호출방법 : this.$mySecondMethod();
     */
    app.config.globalProperties.$mySecondMethod = function () {
      console.log("mySecondMethod 호출");
    };

    /**
     * 날짜 조회
     * format : yyyyMMddhhmm
     */
    app.config.globalProperties.$timeSt = function (dt) {
      function pad(num) {
        num = num + "";
        return num.length < 2 ? "0" + num : num;
      }

      const d = new Date(dt);
      const yyyy = d.getFullYear();
      const MM = d.getMonth() + 1;
      const dd = d.getDate();
      const hh = d.getHours();
      const mm = d.getMinutes();

      return yyyy + pad(MM) + pad(dd) + pad(hh) + pad(mm);
    };

    /**
     * 브라우저 언어 조회
     */
    app.config.globalProperties.$getLanguage = function () {
      return navigator.language || navigator.userLanguage;
    };

    /**
     * 현재 시간 YYYY-MM-DD hh:mm:ss 포맷으로 반환
     */
    app.config.globalProperties.$getTimeStamp = function () {
      const d = new Date();
      const leadingZeros = (n, digits) =>
        String(n).padStart(digits, "0");

      return `${leadingZeros(d.getFullYear(), 4)}-${leadingZeros(
        d.getMonth() + 1,
        2
      )}-${leadingZeros(d.getDate(), 2)} ${leadingZeros(
        d.getHours(),
        2
      )}:${leadingZeros(d.getMinutes(), 2)}:${leadingZeros(
        d.getSeconds(),
        2
      )}`;
    };

    /**
     * 알림창 swal
     */
    app.config.globalProperties.$swal = function (title) {
      swal.fire({
        title,
        icon: "success", // type 대신 icon 사용
        timer: 2000,
        showConfirmButton: false,
      });
    };

    /**
     * axios 공통 함수
     */
    app.config.globalProperties.$axios = async function (param) {
      const token = localStorage.getItem("access_token");
      const apiUrl = param.api_url || this.$CONF.API_URL; // 기본 API URL
      const fullUrl = apiUrl + param.url;
    
      // 로딩 표시
      let loader;
      if (param.loadingBar) {
        const loading = useLoading();
        loader = loading.show({
          color: "#12b8cd",
          loader: "bars",
          width: 50,
          height: 50,
          backgroundColor: "#ffffff",
          opacity: 0.5,
          zIndex: 999,
        });
      }
    
      try {
        // `FormData` 여부 확인 후 `Content-Type` 설정
        let headers = { Authorization: `Bearer ${token}` };
        if (param.data instanceof FormData) {
          headers["Content-Type"] = "multipart/form-data";
        }
    
        const response = await axios({
          method: param.method,
          url: fullUrl,
          headers: headers,
          data: param.data,
          params: param.params,
          responseType: param.responseType || "json",
        });
    
        if (loader) loader.hide(); // 로딩 숨기기
        return response;
      } catch (error) {
        if (loader) loader.hide(); // 로딩 숨기기
        console.error("Axios Error:", error);
        throw error;
      }
    };    

    /**
     * axios 파일 다운로드 함수
     */
    app.config.globalProperties.$axios_download = async function (url, data) {
      const token = localStorage.getItem("access_token");

      // 로딩 표시
      const loading = useLoading();
      const loader = loading.show({
        color: "#12b8cd",
        loader: "spinner",
        width: 50,
        height: 50,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
      });

      try {
        const response = await axios({
          method: "post",
          url: this.$CONF.API_URL + url,
          headers: { 
            Authorization: `Bearer ${token}`
          },
          data,
          responseType: "blob",
        });

        const fileName = data.fileName || "downloaded_file";
        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        loader.hide(); // 로딩 숨기기
      } catch (error) {
        loader.hide(); // 로딩 숨기기
        console.error("Download Error:", error);
        this.$swal(this.$t("alert.50"));
      }
    };

    /**
     * 문자열 줄바꿈 -> <br /> 변환
     */
    app.config.globalProperties.$nl2br = function (str) {
      return str.replace(/\n/g, "<br />");
    };

    /**
     * 숫자에 천 단위 콤마 추가
     */
    app.config.globalProperties.$addComma = function (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    /**
     * 배열 -> 문자열
     */
    app.config.globalProperties.$arrToString = function(arr) {
      // 배열을 문자열로 변환 (구분자는 콤마 ",")
      const result = arr.join(", ");
      //console.log(result); // 출력: "TOPIK, 회화, 문법"
      return result;
    };

    /**
     * 문자열 -> 배열
     */
    app.config.globalProperties.$stringToArr = function(str) {
      if (!str) return []; // null 또는 undefined일 경우 빈 배열 반환
      return str.split(",").map(item => item.trim()); // 문자열을 배열로 변환
    };

    /**
     * 숫자에 포함된 콤마 제거
     */
    app.config.globalProperties.$removeComma = function (num) {
      return num.toString().replace(/,/g, "");
    };

    /**
     * 빈 값 체크
     */
    app.config.globalProperties.$isEmpty = function (val) {
      return (
        val === null ||
        val === undefined ||
        val === "" ||
        (typeof val === "object" && !Object.keys(val).length)
      );
    };

    /**
     * 빈값일때 치환하기
     */
    app.config.globalProperties.$ifEmpty = function(val, replaceVal)  {
      if (val == "" || val == " " || val == null || val == undefined || (val != null && typeof val == "object" && !Object.keys(val).length)) {
        return replaceVal;
      } else {
        return val;
      }
    };

    /**
     * 문자열 자르기
     */
    app.config.globalProperties.$cut_str = function(str, length){
      if(str !== null && str.length > length){
        return str.substr(0, length)+"...";
      }else{
        return str;
      }
    };
    
    /**
     * 날짜 포맷 변환
     * @param {string|Date} date - 변환할 날짜 (ISO 형식 문자열 또는 Date 객체)
     * @param {string} format - 원하는 포맷 (기본값: "YYYY-mm-dd")
     * @returns {string} - 포맷팅된 날짜 문자열
     */
    app.config.globalProperties.$formatDate = function (date, format = "YYYY-mm-dd") {
      if (!date) return "";

      const d = new Date(date);
      if (isNaN(d.getTime())) return "Invalid Date";

      const pad = (num) => (num < 10 ? "0" + num : num);

      const map = {
        YYYY: d.getFullYear(),
        mm: pad(d.getMonth() + 1), // 월은 0부터 시작하므로 +1
        dd: pad(d.getDate()),
        HH: pad(d.getHours()),
        MM: pad(d.getMinutes()),
        SS: pad(d.getSeconds()),
      };

      return format.replace(/YYYY|mm|dd|HH|MM|SS/g, (match) => map[match]);
    };

    /**
     * 파일확장자
     */
    app.config.globalProperties.$getExtension = function(fileName) {      
      var fileLength = fileName.length;    
      var lastDot = fileName.lastIndexOf('.'); 
      var fileExtension = fileName.substring(lastDot+1, fileLength);     
      return fileExtension;    
    };

    /**
     * 천단위 이상의 숫자에 콤마 삽입 및 소숫점 아래 0 이면 소숫점 이하 표시 안함
     */
    app.config.globalProperties.$formatCurrency = function(value) {
      if (!value) {
          return '0';
      }

      const stringValue = value.toString().replace(',', '');
      const numberValue = Number(stringValue);
      let formattedValue;

      if (numberValue % 1 === 0) {
          formattedValue = Math.round(numberValue).toString();
      } else {
          formattedValue = numberValue.toFixed(2);
      }

      // 천 단위마다 콤마 추가
      const withCommas = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return withCommas;
    };

    /**
     * 화면에서 원하는 위치로 이동
     */
    app.config.globalProperties.$scrollToTarget = function(target_id) {
      const targetElement = document.getElementById("target_" + target_id);
      const targetYPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const startingY = window.pageYOffset;
      const duration = 1000; // 1초 동안 스크롤 애니메이션 실행
      const startTime = performance.now();

      const animateScroll = currentTime => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        window.scrollTo(0, startingY + (targetYPosition - startingY) * progress);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    }

    /**
     * 화면에서 원하는 위치로 이동
     * - 스크롤 이동 대상이 "div" 레이어 내부에 있고, 해당 "div"가 자체적으로 스크롤 가능한 경우
     *  예) 
     *    div 선언 : <div class="md-layout-item md-size-100" ref="myScrollContainer" style="overflow: auto;min-height:200px;max-height:700px;">
     *    호출 : @input="scrollToTargetScroll(v_isbn, $refs.myScrollContainer)"
     */
    app.config.globalProperties.$scrollToTargetScroll = function(target_id, obj) {
      const scrollContainer = obj; // Vue ref를 사용하여 스크롤 컨테이너 참조
      const targetElement = document.getElementById("target_" + target_id);
      const targetYPosition = targetElement.getBoundingClientRect().top + scrollContainer.scrollTop;
      const startingY = scrollContainer.scrollTop;
      const duration = 1000; // 1초 동안 스크롤 애니메이션 실행
      const startTime = performance.now();
    
      const animateScroll = currentTime => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        scrollContainer.scrollTop = startingY + (targetYPosition - startingY) * progress;
    
        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };
    
      requestAnimationFrame(animateScroll);
    }

    /**
     * 화면에서 원하는 위치로 이동
     * - 스크롤 이동 대상이 "커스텀컴포넌트" 레이어 내부에 있고, 해당 "커스텀컴포넌트"가 자체적으로 스크롤 가능한 경우
     *  예) 
     *    div 선언 : <md-card-content class="myScrollContainer" style="overflow: auto;min-height:200px;max-height:700px;">
     *    호출 : @input="scrollToTargetScrollDom(v_isbn)"
     */
    app.config.globalProperties.$scrollToTargetScrollDom = function(target_id) {
      // 스크롤 컨테이너를 클래스 이름으로 찾습니다.
      const scrollContainer = document.querySelector('.myScrollContainer');
      if (!scrollContainer) {
        console.error("Scroll container not found");
        return;
      }
    
      // 대상 요소를 ID로 찾습니다.
      const targetElement = document.getElementById("target_" + target_id);
      if (!targetElement) {
        console.error("Target element not found: " + target_id);
        return;
      }
    
      const targetYPosition = targetElement.getBoundingClientRect().top + scrollContainer.scrollTop;
      const startingY = scrollContainer.scrollTop;
      const duration = 1000; // 1초 동안 스크롤 애니메이션 실행
      const startTime = performance.now();
    
      const animateScroll = currentTime => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        scrollContainer.scrollTop = startingY + (targetYPosition - startingY) * progress;
    
        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };
    
      requestAnimationFrame(animateScroll);
    }

    /**
     * 알림창
     * https://www.npmjs.com/package/@kyvg/vue3-notification
     */
    app.config.globalProperties.$notifyVue = function(obj) {
      this.$notify({
        title: obj.title,
        text: obj.text,
        type: obj.type,
        duration: 10000,
        speed: 1000
      })
    };

    app.config.globalProperties.$showConfirmationAndSubmit = async function (requestFn, resetForm, obj) {
      // SweetAlert 모달 띄우기
      const confirmation = await swal.fire({
        title: obj.title,
        text: this.$t('alert.60'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('btn.22'),
        cancelButtonText: this.$t('btn.23'),
      });
    
      if (confirmation.isConfirmed) {
        // 확인 버튼을 눌렀을 때 서버로 전송
        try {
          const response = await requestFn(); // 요청 함수 실행
    
          if (response?.status === 200) {
            swal.fire({
              title: "Success!",
              text: this.$t('alert.61'),
              icon: "success",
              confirmButtonText: "OK",
            });
            
            if(resetForm != null){
              resetForm(); // 폼 초기화
            }

            return "success";
          } else {
            swal.fire({
              title: "Error",
              text: this.$t('alert.69'),
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error(error);
          swal.fire({
            title: "Error",
            text: this.$t('alert.70'),
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        // 취소 버튼을 눌렀을 때
        swal.fire({
          title: "Cancelled",
          text: this.$t('alert.62'),
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    }

  },
};

export default common;
