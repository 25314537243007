import axios from "axios";
import CONF from "@/Config.js";
import router from "@/router";
import {useLoading} from 'vue-loading-overlay'

const $loading = useLoading({
    // options
});

/**
 * initial state
 * 사용 예) this.$store.state.auth.is_login
 */
const state = {
  access_token: "", // 토큰 정보
  user_info: null, // 로그인 사용자 정보
  page_auth_info: null, // 페이지 접근권한 정보
  is_login: false // 로그인 여부
};

/**
 * getters
 * 사용 예) this.$store.getters['auth/getUserInfo'];
 */
const getters = {
  getAccessToken: (state) => state.access_token,
  getIsLogin: (state) => state.is_login,
  getUserInfo: (state) => state.user_info,
  getPageAuthInfo: (state) => (to_path) => {
    let checkPath = 0;
    state.page_auth_info.forEach((element) => {
      if (to_path.includes(element.uri)) checkPath++;
    });

    // 예외 페이지
    let exceptArr = ["/dashboard"];
    if (exceptArr.includes(to_path)) checkPath++;

    return checkPath > 0;
  },
};

/**
 * mutations
 * 사용 예) this.$store.commit('auth/logout','');
 */
const mutations = {
  login(state, payload) {
    state.access_token = payload.token;
    state.user_info = payload.user_info;
    state.is_login = true;
  },
  logout(state) {
    state.access_token = "";
    state.user_info = null;
    state.is_login = false;
  },
  refreshToken(state, payload) {
    state.access_token = payload;
  },
  pageAuthInfo(state, payload) {
    state.page_auth_info = payload;
  }
};

/**
 * actions
 * 사용 예) this.$store.dispatch('auth/logout');
 */
const actions = {
  // 로그인 -> 토큰 반환
  login({ dispatch }, { email, password }) {
    const loader = $loading.show();

    axios
      .post(`${CONF.API_URL}/auth/editor/token`, {
        userEmail: email,
        password,
      })
      .then((res) => {
        if (res.status === 200) {
          loader.hide();

          const loadDt = new Date();
          const expires_in = res.data.expires_in;
          const expire_time = new Date(Date.parse(loadDt) + 1000 * expires_in);

          localStorage.setItem("access_token", res.data.access_token);
          localStorage.setItem("expire_time", expire_time.toISOString());

          dispatch("getMemberInfo");
        }
      })
      .catch((err) => {
        alert("로그인 실패"); // 직접 메시지 추가
        loader.hide();
        console.error(err);
      });
  },

  // 사용자 정보 가져오기 (Promise 반환 추가)
  async getMemberInfo({ commit }) {
    const token = localStorage.getItem("access_token");
    if (!token || token === "null") return;

    try {
      const response = await axios.get(`${CONF.API_URL}/auth/editor/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const user_info = {
        idx: response.data.userIdx,
        name: response.data.userName,
        email: response.data.userEmail,
      };
      commit("login", { token, user_info });

      // 현재 경로 확인 후 리다이렉트 처리
      const currentRoute = router.currentRoute.value;
      const redirect = currentRoute.query.redirect;

      if (currentRoute.name === "Signin Cover" && redirect) {
        router.push(redirect);
      } else if (currentRoute.name === "Signin Cover") {
        router.push({ name: "Sales" });
      }
    } catch (err) {
      alert("사용자 정보를 가져올 수 없습니다.");
      router.push({ name: "Signin Cover" }); // 로그인 페이지로 이동
    }
  },

  refreshToken({ commit }) {
    const token = localStorage.getItem("access_token");

    axios
      .get(`${CONF.API_URL}/auth/editor/refresh`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          const loadDt = new Date();
          const expires_in = res.data.expires_in;
          const expire_time = new Date(Date.parse(loadDt) + 1000 * expires_in);
          const refresh_token = res.data.access_token;

          localStorage.setItem("access_token", refresh_token);
          localStorage.setItem("expire_time", expire_time.toISOString());

          commit("refreshToken", refresh_token);
        }
      })
      .catch(() => {
        alert("토큰 갱신 실패");
      });
  },

  logout({ commit }) {
    localStorage.removeItem("access_token");
    commit("logout");

    router.push({ name: "Signin Cover" });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
